import { createBrowserRouter, json } from 'react-router-dom';

import { langs } from '~/config';
import { route as notFoundPageRoute } from '~/containers/NotFoundPage';
import { route as paymentPageRoute } from '~/containers/PaymentPage';
import { LANG_PARAM_NAME } from '~/hooks/useLang';

export const router = createBrowserRouter([
  {
    path: `/:${LANG_PARAM_NAME}/`,

    children: [paymentPageRoute, notFoundPageRoute],
    errorElement: notFoundPageRoute.element,
    loader({ params }) {
      if (!langs.some(({ code }) => code === params[LANG_PARAM_NAME])) {
        throw json(null, { status: 404 });
      }

      return null;
    },
  },

  notFoundPageRoute,
]);
