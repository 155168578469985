import * as React from 'react';
import type { RouteObject } from 'react-router-dom';

import { loadable } from '~/utils/loadable';

type RouteCreator = (
  params: Pick<RouteObject, 'path'> & {
    loader: () => Promise<{ default: React.ComponentType }>;
  },
) => RouteObject;

export const createRoute: RouteCreator = (params) => {
  const { loader, path } = params;

  const Element = loadable(loader);

  return {
    element: <Element />,
    path,
  };
};
