import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import './App.ui';

export const App = (): JSX.Element => {
  return (
    <>
      <Helmet titleTemplate="%s | Pike" />

      <RouterProvider router={router} />
    </>
  );
};
