import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import * as ReactDOM from 'react-dom/client';

import { App } from '~/containers/App';

const $container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot($container!);

root.render(<App />);
