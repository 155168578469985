import { useParams } from 'react-router-dom';

import { LANG_PARAM_NAME } from './constants';

import { DEFAULT_LANG, langs } from '~/config';

export type LangCode = (typeof langs)[number]['code'];

export type UseLangHook = () => {
  lang: LangCode;

  langFromUrl?: LangCode;
};

// Хук, забирающий локаль из URL.
export const useLang: UseLangHook = () => {
  const { [LANG_PARAM_NAME]: lang } = useParams<{
    [LANG_PARAM_NAME]?: LangCode;
  }>();

  return {
    lang: lang ?? DEFAULT_LANG,
    langFromUrl: lang,
  };
};
